import React from "react";
import Image from "next/image";

const MobileDefault = ({ ...props }) => {

	const prop = props;

	return (
		<div className="mobile-default">

			<a href={prop.link} className="article-image-container">
				<Image
					src={prop.image}
					alt="article"
					layout="fixed"
					objectFit="cover"
					// priority
					width={361}
					height={180}
					quality={70}
				/>
			</a>
			<a href={prop.catSlug} className="tag">
				{prop.category}
			</a>
			<a href={prop.link} className="title">{prop.title}</a>
			<div className="author">
				<a href={prop.authorSlug} className="author-image-container">
					<Image
						src={prop.authorImage}
						alt="author"
						layout="fixed"
						objectFit="cover"
						width={17}
						height={17}
						quality={70}
					/>
				</a>
				<p className="author-name">
					<a href={prop.authorSlug}>
						{prop.authorName}
					</a>
					・
					{prop.date }
				</p>
			</div>

			<style jsx>
				{`
          .mobile-default {
            display: flex;
            flex-flow: column;
          }
          .author {
            display: flex;
            align-items: center;
            gap: 10px;
            margin: 16px 0 0 0;
          }
          .article-image-container {
            display: grid;
            width: 100%;
            overflow: hidden;
            aspect-ratio: 2/1;
          }
          .image-article {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .tag {
            font-family: Roboto;
            font-size: 10px;
            font-weight: 700;
            line-height: 12px;
            letter-spacing: 0em;
            text-align: left;
            color: #E66983;
            text-transform: uppercase;
            margin: 16px 0 8px 0;
          }
          .title {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: left;
            color: #1A1E23;
          }
          .author-image-container {
            width: 17px;
            height: 17px;
            border-radius: 50%;
            overflow: hidden;
          }
          .author-name {
            font-family: Roboto;
            font-size: 10px;
            font-weight: 400;
            line-height: 12px;
            letter-spacing: 0em;
            text-align: left;
            color: #919EAB;
            text-transform: uppercase;
            margin: 0;
          }
          .author-name a {
            color: #919EAB;
          }
        `}
			</style>
		</div>
	);

};

export default MobileDefault;
